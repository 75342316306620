<template>
	<section class="layer" style="display:block">
		<div class="layer__dim"></div>
        <!-- [D] 가로/세로 가운데 정렬 레이어 일 경우 layer__wrap--centered 클래스 추가 -->
		<div class="layer__wrap layer__wrap--centered">
			<div class="layer-message">
                <div class="layer-message__top">
                    <h3 class="layer-message__title">
	                    이전 화면으로 돌아가시나요?
                    </h3>
                    <p class="layer-message__desc">
                        입력하신 정보가 모두 사라집니다.
                    </p>
                </div>
                <div class="layer-message__btns">
                    <button type="button" class="btn"  @click="handleClose()">
                        취소
                    </button>
                    <button type="button" class="btn skyblue"  @click="handleSubmit()">
                        뒤로가기
                    </button>
                </div>
			</div>
		</div>
	</section>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
    props: ["handleClose", "handleSubmit"],
    setup(props) {
        const { t }= useI18n() 
        onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
        return{
            t,  //번역필수 모듈
		  i18n,
        }
    }
}
</script>